/******************************************************************************
** Display doubleclick ad units
******************************************************************************/

// Detect site
let site;
if (location.host.indexOf('postgrad') > -1) {
  site = 'postgrad-';
} else if (location.host.indexOf('llmstudy') > -1) {
  site = 'llmstudy-';
}

// Collect our django settings
const PG = window.PG;


/*
** Serve standard doubleclick ads
*/

const setupAds = (mobile = false) => {
  // Load gpt
  let gptScript = document.createElement('script');
  gptScript.defer = true;
  gptScript.src = "//securepubads.g.doubleclick.net/tag/js/gpt.js";
  document.body.append(gptScript);

  // Ensure googletag.cmd exists
  window.googletag = window.googletag || {};
  window.googletag.cmd = googletag.cmd || [];


  /*
  ** Define all ad slots on the site
  */

  class Slot {
    constructor(code, size, id, el) {
      this.code = site + code;
      this.size = size;
      this.id = id;
      this.el = el || document.getElementById(id);
    }
  }

  // Ad slot sizes
  const LEADERBOARD = [728, 90],
    MOBILE_LEADERBOARD = [320, 50],
    SKYSCRAPER = [160, 600],
    BOX = [300, 250],
    HOME_FEATURE = [395, 277],
    BANNER = [468, 60],
    BUTTON = [120, 60],
    POSTGRAD_SPONSORSHIP = [1080, 300]
  ;

  // Make list of slots on this page
  // Define all slots across the site as [ad_unit_suffix, size, div_id]
  let slots;

  if(mobile){
    slots = [
      ['mobile-leaderboard', [MOBILE_LEADERBOARD], 'dfp-mobile-leaderboard'],
      ['homepage-feature-course', [180, 150], 'dfp-homepage-feature-course'],
    ]
  }
  else {
    slots = [
      ['sponsorship', [POSTGRAD_SPONSORSHIP], 'dfp-sponsorship'],
      ['leaderboard', [LEADERBOARD, BANNER], 'dfp-leaderboard'],
      ['skyscraper-1', SKYSCRAPER, 'dfp-skyscraper-1'],
      ['skyscraper-2', SKYSCRAPER, 'dfp-skyscraper-2'],
      ['skyscraper-3', SKYSCRAPER, 'dfp-skyscraper-3'],
      ['content', BOX, 'dfp-content'],
      ['content-banner', [LEADERBOARD, [700, 100]], 'dfp-content-banner'],
      ['homepage-feature', HOME_FEATURE, 'dfp-homepage-feature'],
      ['homepage-feature-course', [180, 150], 'dfp-homepage-feature-course'],
      // Business feature has to support legacy size for now
      ['business-feature', [HOME_FEATURE, [335, 236]], 'dfp-business-feature'],
      ['online-feature', [HOME_FEATURE, [335, 236]], 'dfp-online-feature'],
      ['button-1', BUTTON, 'dfp-button-1'],
    ];
  }

  slots = slots.map((slot) => { return new Slot(...slot); }).filter((slot) => { return slot.el; });

  // Add buttons
  let buttonCount = 1,  /* Only 1 is used now */
    buttonEl = document.getElementById('dfp-button-1'),
    adCon = document.getElementById('dfp-ad-con'),
    i, newButton
  ;
  if (!buttonEl || !adCon) {
    // Unknown template, abort - probably admin site
    return;
  }

  for (i=2; i<=buttonCount; i++) {
    newButton = buttonEl.cloneNode(true);
    newButton.id = 'dfp-button-' + i;
    buttonEl.parentElement.appendChild(newButton);
    slots.push(new Slot('button-' + i, BUTTON, 'dfp-button-' + i, newButton));
  }

  // Start with side ad container hidden
  adCon.style.display = 'none';

  // Set up googletag when it's ready
  window.googletag.cmd.push(() => {
    let googletag = window.googletag;
    if (!PG) {
      return;
    }
    // Show ad container
    adCon.style.display = 'block';

    // Start out with all ad units hidden
    googletag.pubads().collapseEmptyDivs(true);

    // Define slots
    for (let slot of slots) {
      googletag
        .defineSlot('/' + PG.dfp_id + '/' + slot.code, slot.size, slot.id)
        .addService(googletag.pubads())
      ;
    }

    // Page-wide configuration
    googletag.pubads().enableSingleRequest();
    if (PG.dfp_target) {
      for (let dfp_key of Object.keys(PG.dfp_target)) {
        let dfp_val = PG.dfp_target[dfp_key];
        if (!Array.isArray(dfp_val)) {
          dfp_val = [dfp_val];
        }
        googletag.pubads().setTargeting(dfp_key, dfp_val);
      }
    }

    // Initialise services
    googletag.enableServices();

    // Display tags
    for (let slot of slots) {
      googletag.display(slot.id);
    }
  });

};


/*
** Direct ads are special - they don't import from doubleclick directly
*/

const addDirect = (selector, ad_unit, size='180x150') => {
  let $container = $(selector);
  if (!$container) {
    return;
  }
  let $a = $container.children('a'),
    $box = $a.children('div'),
    cachebuster = Math.random() * 10000000000000000000,
    network = PG.dfp_id,
    query = `?iu=/${network}/${site}${ad_unit}&sz=${size}&c=${cachebuster}`
  ;
  $a.attr('href', '//pubads.g.doubleclick.net/gampad/jump' + query);
  $box.css('background-image', 'url(//pubads.g.doubleclick.net/gampad/ad' + query + ')');
};


if (site) {
  let size = ((window.visualViewport && window.visualViewport.width) || window.innerWidth);
  if (size > 990) {
    setupAds(false);
  }
  else if (size > 1) { 
    setupAds(true);
    addDirect('#dfp-homepage-feature', 'homepage-feature', '395x277');
    addDirect('#dfp-business-feature', 'business-feature', '395x277');
    addDirect('#dfp-online-feature', 'online-feature', '395x277');
  } else {
  }

  // Ads with associated text never import from doubleclick
  addDirect('#home-institute', 'homepage-institute');
  addDirect('#home-course', 'homepage-course');
  addDirect('#business-institute', 'business-institute');
  addDirect('#online-learning-institute', 'online-learning-institute');
}
